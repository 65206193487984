import React from "react";
import { StyledDropdownInput, StyledInputWrapper } from "../../index.style";
import Content from "../../../../../../components/Content";
import Header from "../../../../../../components/Header";
import { SelectChangeEvent } from "@mui/material";
import {
  specialConditionTypes,
  yesNoChoice,
} from "../../../../../../app/costants";
import NumberField from "../../../../../../components/NumberField/NumberField";
import AppContext, { AppContextTypes } from "app/index.ctx";

const AdvisoryServiceFeeFields = (): React.ReactElement => {
  const {
    asfMinFeeWaived,
    setASFMinFeeWaived,
    asfSpecialCondition,
    setASFSpecialCondition,
    asfSpecialConditionAmount,
    setASFSpecialConditionAmount,
    advisoryServiceFee,
  } = React.useContext(AppContext) as AppContextTypes;

  const disabled = asfSpecialCondition === specialConditionTypes[0].value;

  const onSpecialConditionTypeSelected = (
    event: SelectChangeEvent<string | number>
  ) => {
    if (setASFSpecialCondition) {
      setASFSpecialCondition(event.target.value as string);
    }
    setASFSpecialConditionAmount?.(undefined);
  };

  const onAmountChanged = (value: string) => {
    if (setASFSpecialConditionAmount) {
      setASFSpecialConditionAmount(parseFloat(value));
    }
  };

  const onMinFeeWaivedChanged = (event: SelectChangeEvent<string | number>) => {
    if (setASFMinFeeWaived) {
      setASFMinFeeWaived(
        (event.target.value as string) === yesNoChoice[1].value
      );
    }
  };

  if (advisoryServiceFee === 0) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <Content>
        <Header title="Advisory service fee fields" />
      </Content>
      <StyledInputWrapper>
        <StyledDropdownInput
          label="Special condition type"
          name="special-condition-type"
          items={specialConditionTypes}
          variant="outlined"
          onChange={onSpecialConditionTypeSelected}
          defaultValue={asfSpecialCondition}
        />

        <NumberField
          label="Special condition amount"
          name="special-condition-amount"
          variant="outlined"
          value={disabled ? "" : asfSpecialConditionAmount}
          onChange={onAmountChanged}
          endAdornment={disabled ? "" : asfSpecialCondition}
          disabled={disabled}
          min={0}
        />

        <StyledDropdownInput
          label="Minimum fee waived?"
          name="minimum-fee-waived"
          items={yesNoChoice}
          variant="outlined"
          defaultValue={
            !(asfMinFeeWaived as boolean)
              ? yesNoChoice[0].value
              : yesNoChoice[1].value
          }
          onChange={onMinFeeWaivedChanged}
        />
      </StyledInputWrapper>
    </React.Fragment>
  );
};

export default AdvisoryServiceFeeFields;
